.root {
	border-radius: 70px;
	border: 1px solid var(--color-btn-primary);
	cursor: pointer;
	background: transparent;
	font-family: var(--font-secondary);
	box-sizing: border-box;
	white-space: nowrap;
	font-weight: 400;
	letter-spacing: 0.25px;
	height: fit-content;
	user-select: none;
	
	&[variant="primary"] {
		border: 1px solid var(--color-5);
		color: var(--color-2);
		background: var(--color-5);
		backdrop-filter: blur(37px);
		&:hover {
			opacity: 0.8;
		}
		&:active {
			opacity: 0.6;
		}
		&:disabled {
			background: rgba(255, 255, 255, 0.1);
			opacity: 0.3;
			border: 1px solid var(--color-5);
			backdrop-filter: blur(37px);
			color: var(--color-1);
			pointer-events: none;
		}
	}

	&[variant="ghost"] {
		border: 1px solid var(--color-5);
		color: var(--color-1);
		background: rgba(31, 36, 47, 0.2);
		backdrop-filter: blur(37px);
		&:hover {
			opacity: 0.8;
		}
		&:active {
			opacity: 0.6;
		}
		&:disabled {
			background: rgba(255, 255, 255, 0.1);
			opacity: 0.3;
			border: 1px solid var(--color-5);
			backdrop-filter: blur(37px);
			color: var(--color-1);
			pointer-events: none;
		}
	}

	&[sizing="s"] {
		font-size: 12px;
		line-height: 18px;
		padding: 6px 12px;
	}

	&[sizing="m"] {
		font-size: 16px;
		line-height: 28px;
		padding: 8px 24px;
	}

	&[sizing="l"] {
		font-size: 18px;
		line-height: 22px;

		padding: 9px 20px;
	}

	&[sizing="xl"] {
		font-size: 24px;
		line-height: 28px;
		padding: 12px 32px;
	}

	&[sizing="xxl"] {
		font-weight: 500;
		font-size: 18px;
		line-height: 30px;
		padding: 15px 135px;
	}
}
