@import "Themes/constants.scss";

.root {
	display: flex;
	align-items: center;
	gap: 24px;
	user-select: none;

	@media screen and (max-width: $screen-s) {
		gap: 16px;
	}

	.input {
		background: rgba(35, 37, 39, 0.6);
		border: 1px solid rgba(255, 255, 255, 0.1);
		border-radius: 4px;
		width: 66px;
		height: 46px;

		@media screen and (max-width: $screen-s) {
			width: 48px;
			height: 46px;
		}

		font-family: var(--font-primary);
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 30px;
		letter-spacing: 0.25px;
		color: var(--color-1);

		display: flex;
		justify-content: center;
		pointer-events: none;
		text-align: center;
	}

	.button {
		cursor: pointer;
	}
}

