@import "Themes/constants.scss";

.root {
	max-width: 100vw;
	position: relative;
	display: flex;
	align-items: center;

	@media screen and (max-width: $screen-s) {
		justify-content: center;
	}


	.video {
		position: absolute;
		z-index: -1;
		object-fit: cover;
		height: 100%;
		width: 100%;

		@media screen and (max-width: $screen-s) {
			display: none;
		}
	}

	> img {
		position: absolute;
		z-index: -1;
		object-fit: cover;
		object-position: 50% 50%;
		height: 100%;
		width: 100%;
		@media screen and (min-width: $screen-s) {
			display: none;
		}
	}

	.box {
		display: flex;
		flex-direction: column;
		background: rgba(35, 37, 39, 0.6);
		border: 1px solid rgba(255, 255, 255, 0.1);
		// backdrop-filter: blur(37px);
		border-radius: 24px;

		padding: 80px 120px 80px 120px;
		max-width: 798px;
		gap: 32px;

		margin-left: 75px;
		margin-right: 75px;
		margin-top: 50px;
		margin-bottom: 50px;

		@media screen and (max-width: $screen-s) {
			padding: 32px;
			max-width: 100%;
			margin-left: 16px;
			margin-right: 16px;
			margin-top: 50px;
			margin-bottom: 50px;
		}
		@media screen and (max-width: $screen-xs) {
			padding: 32px;
			max-width: 100%;
			margin-left: 16px;
			margin-right: 16px;
			margin-top: 50px;
			margin-bottom: 50px;
		}

		.title {
			font-family: var(--font-secondary);
			font-style: normal;
			font-weight: 500;
			font-size: 68px;
			line-height: 76px;
			letter-spacing: 11px;
			color: var(--color-1);
			margin: 0;

			@media screen and (max-width: ($screen-m+100px)) {
				font-size: 50px;
				line-height: 60px;
				letter-spacing: 3px;
			}

			@media screen and (max-width: $screen-s) {
				font-size: 40px;
				line-height: 50px;
				letter-spacing: 3px;
			}
			@media screen and (max-width: $screen-xs) {
				font-size: 27px;
				line-height: 30px;
				letter-spacing: 3px;
			}
		}

		.content {
			font-family: var(--font-secondary);
			font-style: normal;
			font-weight: 300;
			font-size: 18px;
			line-height: 30px;
			color: var(--color-1);
			max-width: 558px;

			@media screen and (max-width: ($screen-m - 200px)) {
				font-size: 16px;
				line-height: 22px;
			}
			
			@media screen and (max-width: $screen-s) {
				font-size: 14px;
				line-height: 22px;
			}
			@media screen and (max-width: $screen-xs) {
				font-size: 12px;
				line-height: 22px;
			}
			@media screen and (max-width: $screen-xxs) {
				font-size: 11px;
				line-height: 22px;
			}
		}

		.button {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 24px;

			padding: 16px 32px;
			max-width: 312px;
			height: 62px;

			background: rgba(31, 36, 47, 0.2);
			border: 1px solid var(--color-10);
			box-sizing: border-box;
			border-radius: 70px;
			cursor: pointer;
			
			> div {
				font-family: var(--font-secondary);
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 30px;
				letter-spacing: 0.25px;
				color: var(--color-1);
				white-space: nowrap;
			}

			@media screen and (max-width: $screen-s) {
				padding: 8px 24px;
				gap: 16px;
				max-width: 263px;
				max-height: 46px;
				
				>div {
					font-size: 16px;
				}
			}
			@media screen and (max-width: $screen-xs) {
				padding: 8px 24px;
				gap: 16px;
				max-width: 230px;
				max-height: 46px;
				
				>div {
					font-size: 13px;
				}
			}
		}

		.link{
			text-decoration: none;
		}
	}
}
