@import "Themes/constants.scss";

.root {
	margin-top: 16px;
	border-radius: 3px;
	overflow: hidden;
	background-color: var(--color-8);
}

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--color-4);
	cursor: pointer;
}

.title {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 30px;
	letter-spacing: 0.25px;
	color: var(--color-title-1);
	margin-left: 32px;
	user-select: none;
	@media (max-width: $screen-xs) {
		font-size: 18px;
	}
}

.arrow {
	margin-right: 32px;
	user-select: none;

	svg {
		transform-origin: center;
		transition: transform 300ms $custom-easing;
		transform: rotate(-90deg);
	}

	&[data-open="true"] {
		svg {
			transform: rotate(0);
		}
	}
}

.content {
	display: flex;
	align-items: flex-start;

	&[data-animate="true"] {
		transition: background-color 150ms $custom-easing, height 150ms $custom-easing;
	}
	
	overflow-x: auto;
	overflow-y: hidden;
	background-color: var(--color-9);

	.content-padding {
		margin: 24px;
	}

	&[data-open="false"] {
		background-color: transparent;
	}
}

