@import "Themes/constants.scss";

.root {
	position: fixed;
	z-index: 5;
	width: 100%;
	pointer-events: none;
	background-color: var(--color-background-transparency);
	backdrop-filter: blur(15px);

	@media (max-width: $screen-m) {
		background-color: var(--color-background-transparency);
		backdrop-filter: blur(15px);
		position: fixed;
		transition: transform 500ms $custom-easing;
		&[data-open="1"] {
			transform: translateY(0);
		}
	}

	.desktop {
		display: flex;
		@media screen and (max-width: $screen-m) {
			display: none;
		}
	}

	.logo-desktop {
		width: 25vw;
		max-width: 371px;
		> img {
			width: 25vw;
			max-width: 371px;
			min-width: 100px;
			position: absolute;
			top: -28px;
			pointer-events: all;
		}

		@media screen and (max-width: 1200px) {
			display: none;
		}
	}

	.logo-small {
		flex: 1;
		@media screen and (min-width: 1200px) {
			display: none;
		}
	}

	.right {
		flex: 1;
		height: 85px;
		border-bottom: 1px solid var(--color-1);

		@media screen and (max-width: 1200px) {
			padding-bottom: 16px;
		}

		.nav-container {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 51px;
			padding-top: 20px;
			padding-right: 44px;
			pointer-events: all;

			.nav-text {
				font-family: var(--font-secondary);
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 25px;
				color: var(--color-1);
				text-decoration: none;

				&:hover {
					font-weight: 400;
					color: var(--color-3);
				}
			}

			.social-link:hover {
				svg {
					stroke: var(--color-3);
				}
			}

			.insta:hover {
				svg path {
					stroke: var(--color-3);
					stroke-width: 2px;
				}
			}
		}
	}

	.mobile {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--color-1);
		@media screen and (min-width: $screen-m) {
			display: none;
		}

	}

	.burger {
		pointer-events: all;
		padding-right: 19px;
	}
}

[top-menu-status="closed"] {
	@media (max-width: $screen-m) {
		.root {
			&[data-open="-1"] {
				transform: translateY(-100px);
			}
		}
	}
}

