:root {
	--content-max-width: 1440px;

	--font-primary: "Poppins";
	--font-secondary: "stolzl";

	--color-background-body: #000000;

	--color-1: #ffffff;
	--color-2: #000000;
	--color-3: #ffffcd;
	--color-4: #e0e0e0;
	--color-5: #f0cd71;
	--color-6: #ef904a;
	--color-7: #d1976e;
	--color-8: #1e1e1e;
	--color-9: #2f2f2f;
	--color-10: #ffe249;
}

