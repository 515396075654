@import "Themes/constants.scss";

.root {

    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 186px;
    max-height: 78px;
    padding: 12px 0px;
    gap: 4px;

    /* Background/Stroke */
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    white-space: nowrap;
    text-align: center;

    @media screen and (max-width: $screen-s) {
        max-width: 160px;
        max-height: 74px;
    }

    @media screen and (max-width: $screen-xs) {
        max-width: 120px;
        max-height: 74px;
    }

    .legend-text{
        font-family: var(--font-secondary);
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.25px;
        color: var(--color-1);
        margin-top: 4px;
        margin-bottom: 0;
        margin-right: 74px;
        margin-left: 74px;

        @media screen and (max-width: $screen-xs) {
            font-size: 12px;
            line-height: 18px;
        }
    }

    .price-text {
        font-family: var(--font-primary);
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: var(--color-1);
        margin-top: 0;
        margin-right: 57px;
        margin-left: 57px;
        margin-bottom: 4px;

        @media screen and (max-width: $screen-xs) {
            font-size: 16px;
            line-height: 22px;
        }
    }
}