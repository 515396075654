@import "../Base/classes.module.scss";
@import "Themes/constants.scss";

.root {
	margin: 0;
	background-image: url("/assets/images/pages/mint/background/mint_background.png");
	background-size: cover;
	padding-top: 250px;
	padding-bottom: 90px;
	padding-left: 16px;
	padding-right: 16px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	// flex-wrap: wrap;
	gap: 3vw;

	&[data-minted-closed="true"] {
		align-items: center;
	}

	@media screen and (max-width: $screen-m) {
		padding-top: 120px;
		padding-bottom: 32px;
		flex-wrap: wrap;
	}

	.blister-image {
		max-height: 658px;
		@media (max-width: $screen-m) {
			max-height: 100%;
		}
		@media (max-width: $screen-s) {
			width: 100%;
		}
	}

	.sale-container {
		display: flex;
		flex-direction: column;
		gap: 40px;
		max-width: 900px;
		@media (max-width: $screen-s) {
			align-items: center;
		}

		.title-container {
			display: flex;
			flex-direction: column;

			font-family: var(--font-secondary);
			font-style: normal;
			font-weight: 500;
			gap: 20px;

			@media (max-width: $screen-s) {
				align-items: center;
				margin-top: 32px;
			}

			.phase-title {
				font-size: 30px;
				line-height: 44px;
				letter-spacing: 3.5px;
				text-transform: uppercase;
				color: var(--color-3);
				text-shadow: 0px 0px 34px var(--color-6), 0px 0px 24px var(--color-7);
				@media (max-width: $screen-s) {
					font-size: 18px;
					line-height: 30px;
				}
			}

			.main-title {
				font-size: 50px;
				line-height: 60px;
				letter-spacing: 3.5px;
				text-transform: uppercase;
				color: var(--color-1);
				@media (max-width: $screen-s) {
					text-align: center;
					width: 220px;
					font-size: 28px;
					line-height: 36px;
				}
			}
			.sub-title {
				display: flex;
				flex-direction: column;
				@media (max-width: $screen-s) {
					margin-top: 8px;
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.launched-by-title {
					font-weight: 400;
					font-size: 18px;
					line-height: 30px;
					letter-spacing: 0.25px;
					color: var(--color-1);
					margin: 0px 0px;
					@media (max-width: $screen-s) {
						font-size: 14px;
						line-height: 22px;
					}
				}

				.powered-by-title {
					font-family: var(--font-secondary);
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 22px;
					letter-spacing: 0.25px;
					color: var(--color-1);
					margin-top: 20px;
					@media (max-width: $screen-s) {
						font-size: 14px;
						line-height: 22px;
					}
				}
			}
			.title-loading {
				background: linear-gradient(270deg, #e0e0e0 0%, rgba(230, 230, 230, 0) 100%);
				border-radius: 25px;
				width: 217px;
				height: 17.5px;
			}

			.social-container {
				display: flex;
				flex-direction: column;
				padding-top: 30px;
				gap: 20px;

				@media (max-width: $screen-m) {
					padding-bottom: 56px;
				}
				.social-row {
					display: flex;
					align-items: center;
					gap: 10px;
					font-family: var(--font-secondary);
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 22px;
					letter-spacing: 0.25px;
					color: var(--color-1);

					.logo img {
						width: 50px;
						height: 50px;
					}

					@media (max-width: $screen-s) {
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 30px;
					}
				}
			}
		}

		.info-container {
			display: flex;
			flex-direction: column;
			background: rgba(35, 37, 39, 0.6);
			border: 1px solid rgba(255, 255, 255, 0.1);
			border-radius: 24px;
			padding: 32px;
			gap: 32px;
			margin-top: 23px;
			align-self: stretch;

			.remaining-pass {
				display: none;
			}
		}

		.eligible-text-container {
			.glowing-title {
				font-family: var(--font-primary);
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 28px;
				color: var(--color-3);
				text-align: center;
				text-shadow: 0px 0px 34px var(--color-6), 0px 0px 24px var(--color-7);
			}

			.legend {
				font-family: var(--font-secondary);
				font-style: normal;
				font-weight: 300;
				font-size: 18px;
				line-height: 30px;
				text-align: center;
				font-feature-settings: "kern" off;
				color: var(--color-1);
			}
		}

		.mint-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			gap: 96px;

			@media (max-width: $screen-s) {
				gap: 27px;
			}

			.border {
				border: 1px solid rgba(255, 255, 255, 0.1);
			}

			@media (max-width: $screen-xs) {
				flex-direction: column;
				align-items: center;
				gap: 20px;

				.border {
					border: none;
				}
			}
		}

		.mint-button {
			align-self: center;

			@media (max-width: $screen-s) {
				> button {
					padding: 8px 72px;
				}
			}
		}
	}

	.bottom-part-container {
		display: flex;
		flex-direction: row;
		justify-content: center;

		.spacing-div {
			@media (max-width: $screen-m) {
				display: none;
			}
		}
	}

	.video {
		object-fit: cover;
		border-radius: 24px;
		max-height: 658px;
		@media (max-width: $screen-l) {
			max-height: 580px;
		}
		@media (max-width: $screen-m) {
			max-height: 100%;
			width: 100%;
		}

		&::-webkit-media-controls-mute-button {
			display: none;
		}

		&::-webkit-media-controls-volume-slider {
			display: none;
		}
		&::-webkit-media-controls-volume-control-container {
			display: none;
		}
	}
}

