@import "Themes/constants.scss";

.root {
	z-index: 3;
	@media (min-width: $screen-m) {
		display: none;
	}

	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	max-height: 100vh;

	.nav {
		opacity: 1;
		overflow: auto;
		position: relative;
		z-index: 2;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
		font-size: 16px;
		max-height: 100vh;
		overflow: auto;
		transition: transform 400ms $custom-easing, opacity 350ms $custom-easing;
		transform: translateY(0);
	}

	.shadow {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1;
		position: absolute;
		opacity: 1;
		transition: opacity 300ms $custom-easing;
	}

	&[status="closed"] {
		pointer-events: none;
		.nav {
			transform: translateY(-100%);
			opacity: 0;
		}

		.shadow {
			opacity: 0;
		}

		.top-menu-nav {
			.item {
				transform: translateY(-40px);
				opacity: 0;
			}
		}
	}

	.top-menu-nav {
		display: flex;
		position: relative;
		flex-direction: column;
		align-items: center;
		gap: 40px;
		padding: 32px 0 32px 0;
		margin-top: 71px;
		background-color: var(--color-background-transparency);
		backdrop-filter: blur(15px);

		.item {
			transition: transform 300ms 100ms $custom-easing, opacity 300ms 150ms $custom-easing;
			transform: translateY(0);
			opacity: 1;

			&:hover {
				svg {
					stroke: var(--color-3);
				}
			}
		}
		.insta:hover {
			svg path {
				stroke: var(--color-3);
				stroke-width: 2px;
			}
		}

		.nav-text {
			font-family: var(--font-secondary);
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 25px;
			color: var(--color-1);
			text-decoration: none;

			&:hover {
				font-weight: 400;
				color: var(--color-3);
			}
		}
	}

	.top-menu-nav a {
		text-decoration: none;
		color: var(--color-text);
	}

	.top-menu-burger {
		align-self: flex-start;
		padding: 0px 32px;
	}
}

