@import "Themes/constants.scss";

.root {
	background-color: var(--color-8);
	display: flex;
	@media (max-width: $screen-m) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: 48px;
	}
	img {
		object-fit: cover;
		width: 40vw;
		@media (max-width: $screen-m) {
			max-height: 500px;
			width: 90vw;
			border-radius: 12px;
		}
	}
}

/* Note: Once we have the correct file (a video), replace "img" per "video" */

.content-container {
	font-family: var(--font-secondary);
	font-style: normal;
	color: var(--color-1);
	padding: 90px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 16px;
	flex-grow: 4;
	max-width: 1000px;
	margin: auto;
	@media screen and (max-width: $screen-m) {
		padding: 40px 0;
		max-width: none;
		width: 90vw;
	}
}

.glowing-title {
	font-size: 28px;
	line-height: 44px;
	letter-spacing: 3.5px;
	text-transform: uppercase;
	color: #ffffcd;
	text-shadow: 0px 0px 34px var(--color-6), 0px 0px 24px var(--color-7);
	margin: 0;

	@media screen and (max-width: $screen-s) {
		font-size: 18px;
		line-height: 30px;
		letter-spacing: normal;
	}
}

.main-title {
	font-weight: 400;
	font-size: 48px;
	line-height: 60px;
	letter-spacing: 3.5px;
	margin: 0;

	@media screen and (max-width: $screen-s) {
		font-size: 27px;
		line-height: 32px;
		letter-spacing: normal;
	}
}

.description {
	font-weight: 300;
	font-size: 18px;
	line-height: 30px;
	margin-top: 40px;
	align-items: center;


	.discord {
		display: column;
		align-items: center;
		.logo img {
			margin-left: 15px;
			width: 50px;
			height: 50px;
		}
		.discord-link {
			margin-top: 10px;

			@media screen and (max-width: $screen-s) {
				display:flex;
				justify-content: center;
			}
		}
	}
	@media screen and (max-width: $screen-s) {
		font-size: 14px;
		line-height: 22px;
		letter-spacing: 0.25px;
	}
}
