@import "Themes/constants.scss";

.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--color-4);
	background-image: url("/assets/images/footer/background.png");
	background-position: center;
	background-size: cover;
	overflow: hidden;
}

.content {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 93px;
	padding-top: 80px;
	padding-bottom: 56px;
	padding-left: 100px;
	padding-right: 100px;
	max-width: 1292px;
	border-bottom: 1.5px solid rgba(255, 255, 255, 0.3);

	@media screen and (max-width: $screen-m) {
		padding-top: 16px;
		padding-bottom: 16px;
		flex-direction: column;
		gap: 0;
		padding-left: 0;
		padding-right: 0;
	}
}

.logo img {
	width: 38vw;
	max-width: 483px;
	min-width: 300px;
}

.title {
	font-weight: 700;
	font-size: 48px;
	line-height: 60px;
	letter-spacing: 3.5px;
	text-transform: uppercase;
	white-space: nowrap;

	@media screen and (max-width: $screen-m) {
		font-weight: 500;
		font-size: 28px;
		line-height: 44px;
		text-align: center;
	}
	@media screen and (max-width: $screen-xxs) {
		font-weight: 500;
		font-size: 24px;
		line-height: 44px;
		text-align: center;
	}
}

.newsletter-frame {
	margin-top: 40px;
	border: none;
	width: 600px;
	height: 80px;
	outline: none;
	overflow: hidden;
	display: flex;
	flex-direction: flex-start;

	@media screen and (max-width: $screen-m) {
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 598px;
	}
	@media screen and (max-width: $screen-s) {
		display: flex;
		justify-content: center;
		flex-direction: column;
		max-width: 100%;
		height: 150px;
	}
	@media screen and (max-width: $screen-xs) {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: column;
		width: 420px;
		height: 160px;
	}
}

.social-container {
	display: flex;
	gap: 32px;
	padding-top: 32px;

	@media screen and (max-width: $screen-m) {
		justify-content: center;
	}
}

.copyright {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	padding-top: 28px;
	padding-bottom: 120px;

	@media screen and (max-width: $screen-m) {
		font-weight: 300;
		font-size: 12px;
		line-height: 22px;
		padding-bottom: 30px;
	}
}
