@import "Themes/constants.scss";

.item {
	display: flex;
	justify-content: center;
	.boxList {
		position: relative;
		margin: 60px 0;
		color: var(--color-1);
		font-size: 18px;
		line-height: 30px;
		width: 456px;
		padding: 24px;



		.borderBox {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: transparent;
			border: 1px solid var(--color-3);
			border-radius: 8px;
		}

		&[border-variant="cut"] {
			padding: 16px 24px;
			.borderBox {
				
				mask-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 30px);;
			}
		}

		@media screen and (max-width: $screen-m) {
			width: 50vw;
			font-size: 16px;
			margin-top: 24px;
			margin-bottom: 80px;
		}

		ul {
			padding: 0 0 0 24px;
			margin: 0;

			@media screen and (max-width: $screen-m) {
				padding: 0 0 0 20px;
			}
		}

		li {
			@media screen and (max-width: $screen-m) {
				padding: 0 0 10px 0;
			}
		}
	}

	&:last-child {
		.boxList {
			@media screen and (max-width: $screen-m) {
				margin-bottom: 0;
			}
		}
	}

	&[data-variant="current"] {
		.boxList {
			background-color: var(--color-3);
			color: var(--color-2);
			font-weight: 500;
		}
	}

	&[data-variant="next"] {
		.boxList {
			background-color: var(--color-2);
		}
	}

	.left {
		display: flex;
		justify-content: flex-end;
		width: 250px;
		padding-right: 32px;

		@media screen and (max-width: $screen-m) {
			display: none;
		}
	}

	.label {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: var(--color-3);
		font-weight: 300;
		font-size: 22px;
		line-height: 39px;
		gap: 8px;

		@media screen and (max-width: $screen-m) {
			align-items: flex-start;
			font-size: 16px;
			font-weight: 300;
			line-height: 20px;
		}

		&[date-variant="title"] {
			align-items: flex-start;
			font-weight: 700;
			font-size: 31px;
			line-height: 39px;

			div:first-of-type {
				text-shadow: 0px 0px 34px #ef904a, 0px 0px 24px #d1976e;
			}
			@media screen and (max-width: $screen-m) {
				font-weight: 500;
				font-size: 24px;
				line-height: 25px;
			}
		}

		.context {
			color: var(--color-1);
			font-weight: 700;
			font-size: 31px;

			@media screen and (max-width: $screen-m) {
				font-weight: 500;
				font-size: 24px;
				line-height: 25px;
			}
		}
	}

	.middle {
		display: flex;
		flex-direction: column;
		position: relative;
		align-self: stretch;

		.lineVertical {
			border-right-width: 4px;
			border-right-style: solid;
			border-color: var(--color-3);
			box-shadow: 0px 0px 14px rgba(205, 159, 89, 0.9);
			align-self: center;
			flex: 1;

			&[data-variant="empty"] {
				border-right-width: 1px;
				border-right-style: dashed;
				border-color: rgba(255, 255, 255, 0.5);
				box-shadow: none;
			}
		}

		.lineVertical:first-child {
			@media screen and (max-width: $screen-m) {
				display: none;
			}
		}
		.point {
			font-size: 0;
		}
	}
	.right {
		display: flex;
		flex-direction: row;
		align-items: center;

		.label {
			display: none;
			width: 250px;

			@media screen and (max-width: $screen-m) {
				display: flex;
			}
		}

		.lineHorizontal {
			border-top: 1px dashed rgba(255, 255, 255, 0.5);
			width: 69px;

			@media screen and (max-width: $screen-m) {
				display: none;
			}
		}

		@media screen and (max-width: $screen-m) {
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: column;
			padding-left: 32px;
		}
	}
}

