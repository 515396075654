@import "Themes/constants.scss";

.root {
	font-family: var(--font-secondary);
	background-color: var(--color-8);
	background-image: url("/assets/images/pages/home/background/realRoadmap.png");
	background-size: cover;
	padding: 16px;
	padding-bottom: 100px;
	font-weight: 300;

	@media screen and (max-width: $screen-s) {
		padding-bottom: 0;
	}

	.title {
		display: flex;
		justify-content: center;
		text-align: center;

		font-family: var(--font-secondary);
		font-style: normal;
		font-weight: 500;
		font-size: 48px;
		line-height: 60px;
		letter-spacing: 5px;
		text-transform: uppercase;
		color: var(--color-1);
		padding-top: 80px;
    padding-bottom: 90px;

		@media screen and (max-width: $screen-s) {
			font-size: 28px;
			line-height: 44px;
			padding-top: 46px;
			padding-bottom: 46px;
		}
		.neon {
			color: var(--color-3);
			text-shadow: 0px 0px 34px var(--color-6), 0px 0px 24px var(--color-7);
		}
	}
}

.content {
	display: flex;
	flex-direction: column;

	.periods {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}
