@import "Themes/constants.scss";

.root {
	font-family: var(--font-primary);
	font-style: normal;

	.content {
		padding: 60px;
		margin: auto;
		width: fit-content;
		max-width: 1280px;

		background: rgba(35, 37, 39, 0.61);
		border: 1px solid rgba(255, 255, 255, 0.1);
		box-sizing: border-box;
		// backdrop-filter: blur(37px);
		border-radius: 48px;
		display: flex;
		align-items: center;
		gap: 65px;

		&[data-text-position="left"] {
			flex-direction: row-reverse;
		}

		@media screen and (max-width: $screen-m) {
			flex-direction: column;
			&[data-text-position="left"] {
				flex-direction: column;
			}
			border-radius: 24px;
		}

		@media screen and (max-width: $screen-m) {
			padding: 24px;
		}

		.media {
			display: flex;

			.video {
				object-fit: cover;
				border-radius: 48px;
				max-width: 627px;
				width: 43vw;
				height: 392px;
				object-fit: cover;
				@media screen and (max-width: $screen-m) {
					max-width: 528px;
					width: 80vw;
				}

				&::-webkit-media-controls-mute-button {
					display: none;
				}

				&::-webkit-media-controls-volume-slider {
					display: none;
				}
				&::-webkit-media-controls-volume-control-container {
					display: none;
				}

				@media screen and (max-width: $screen-s) {
					display: none;
				}
			}

			.mobile {
				@media screen and (min-width: $screen-s) {
					display: none;
				}
			}

			.image {
				object-fit: cover;
				border-radius: 48px;
				@media screen and (min-width: $screen-m) {
					max-width: 473px;
					width: 32vw;
					height: 571px;

					&[data-text-position="left"] {
						max-width: 627px;
						width: 43vw;
						height: 392px;
					}
				}

				@media screen and (max-width: $screen-m) {
					max-width: 528px;
				}
				@media screen and (max-width: $screen-s) {
					width: 80vw;
					max-width: 528px;
					border-radius: 16px;
				}
			}
			&[data-variant="redirect"] {
				cursor: pointer;
			}
		}

		.text-container {
			font-family: var(--font-secondary);
			font-style: normal;
			display: flex;
			max-width: 690px;
			display: flex;
			flex-direction: column;
			gap: 24px;

			&[data-text-position="left"] {
				max-width: 520px;
			}

			@media screen and (max-width: $screen-m) {
				max-width: 520px;
			}

			.title {
				font-weight: 500;
				font-size: 28px;
				line-height: 44px;
				letter-spacing: 3.5px;
				text-transform: uppercase;
				color: var(--color-5);

				@media screen and (max-width: $screen-m) {
					line-height: 44px;
				}
			}

			.description {
				font-weight: 400;
				font-size: 18px;
				line-height: 30px;
				color: var(--color-3);

				@media screen and (max-width: $screen-m) {
					font-size: 14px;
					line-height: 22px;
				}
			}

			.text {
				font-weight: 300;
				font-size: 18px;
				line-height: 30px;
				color: var(--color-1);

				@media screen and (max-width: $screen-m) {
					font-size: 14px;
					line-height: 22px;
				}
			}

			.checklist {
				font-weight: 400;
				font-size: 18px;
				line-height: 30px;
				color: var(--color-1);

				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-row-gap: 24px;
				margin-left: -25px;

				@media screen and (max-width: $screen-m) {
					font-size: 14px;
					line-height: 22px;
					grid-template-columns: none;
					gap: 0px;
				}

				.checkline {
					display: flex;
					align-items: center;

					.check-icon {
						width: 76px;
						height: 66px;
					}
				}
			}
		}
	}
}

