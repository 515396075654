@import "Themes/constants.scss";

.root {
	background-image: url("/assets/images/footer/background.png");
	background-size: cover;
	padding: 0 20px 100px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 120px;

	@media (max-width: $screen-s) {
		gap: 60px;
	}
}

.content {
	display: flex;
	justify-content: center;
	gap: 160px;

	@media (max-width: 1100px) {
		gap: 40px;
	}

	@media (max-width: $screen-m) {
		flex-direction: column;
		gap: 48px;
	}
}
.partner-section {
	display: flex;
	flex-direction: column;
	gap: 80px;
	width: 100%;

	.partners-container-row1 {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;

		@media (max-width: 1200px) {
			gap: 64px;
		}

		@media (max-width: $screen-s) {
			gap: 32px;
			>img {
				width: 70%;
				min-width: 200px;
			}
		}
	}
	.partners-container-row2 {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		align-items: center;

		img {
			max-width: 280px;
		}

		@media (max-width: 1200px) {
			gap: 64px;
		}

		@media (max-width: $screen-s) {
			gap: 32px;
			>img {
				width: 70%;
				min-width: 200px;
			}
		}
	}
}

.title {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 500;
	font-size: 48px;
	line-height: 60px;
	letter-spacing: 5px;
	text-transform: uppercase;
	color: var(--color-1);

	@media screen and (max-width: $screen-s) {
		font-size: 28px;
		line-height: 44px;
	}
}

.header {
	display: flex;
	justify-content: center;
	gap: 26px;
	padding-top: 80px;
	padding-bottom: 32px;
	margin-bottom: 40px;
	flex-wrap: wrap;
	text-align: center;

	@media screen and (max-width: $screen-s) {
		padding-top: 50px;
		padding-bottom: 0;
		margin-bottom: 24px;
	}
	@media screen and (max-width: 500px) {
		gap: 0;
	}
}

.neon {
	color: var(--color-3);
	text-shadow: 0px 0px 34px var(--color-6), 0px 0px 24px var(--color-7);
}
