@import "Themes/constants.scss";

.root {
	background-image: url("/assets/images/pages/home/background/real-world.png");
	background-size: cover;
	padding-left: 16px;
	padding-right: 16px;
}

.title {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 500;
	font-size: 48px;
	line-height: 60px;
	letter-spacing: 5px;
	text-transform: uppercase;
	color: var(--color-1);

	@media screen and (max-width: $screen-s) {
		font-size: 28px;
		line-height: 44px;
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 61px;
}

.header {
	display: flex;
	justify-content: center;
	gap: 26px;
	padding-top: 80px;
	padding-bottom: 32px;
	margin-bottom: 112px;
	text-align: center;
	@media screen and (max-width: $screen-m) {
		flex-direction: column;
		gap: 0;
	}

	@media screen and (max-width: $screen-s) {
		padding-top: 50px;
		padding-bottom: 0;
		margin-bottom: 64px;
		gap: 0;
	}
}

.neon {
	text-shadow: 0px 0px 34px var(--color-6), 0px 0px 24px var(--color-7);
}

.yellow {
	color: var(--color-3);
}

.orange {
	color: var(--color-6);
}

.footer {
	padding-bottom: 100px;
	padding-top: 60px;
}

.tweetsContainer {
	display: flex;
	justify-content: space-around;
	margin-top: 20px;
	flex-wrap: wrap;

	@media screen and (max-width: ($screen-l + 200px)) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	@media screen and (max-width: ($screen-m + 200px)) {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		justify-content: center;
	}
	@media screen and (max-width: $screen-s) {
		justify-content: center;
	}
}

.tweet {
	width: 380px;
	justify-content: center;

	@media screen and (max-width: ($screen-l + 200px)) {
		display: flex;
		width: unset;
	}

	@media screen and (max-width: $screen-s) {
		display: flex;
		width: unset;
	}

	@media screen and (max-width: ($screen-l - 200px)) {
		display: flex;
		width: unset;
		
	}

	@media screen and (max-width: ($screen-xs)) {
		display: flex;
		width: unset;
	}
	@media screen and (max-width: ($screen-xxs)) {
		display: flex;
		width: unset;
	}
	
	> div{
		width:100%;
		display: flex;	
		justify-content: center;
	}
}

.youtube-iframe {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	height: 400px;
}
.youtube-iframe-content {
	border-radius: 8px;
	height: 100%;
	width: 700px;

	@media screen and (max-width: $screen-m) {
		width: 578px;
	}
	@media screen and (max-width: $screen-s) {
		max-height: 300px;
	}
}

.footer-title-container {
	margin-top: 30px;
	display: flex;
	justify-content: center;
	gap: 60px;

	@media screen and (max-width: $screen-s) {
		font-size: 18px;
		line-height: 30px;
		gap: 30px;
	}
	@media screen and (max-width: $screen-xs) {
		flex-direction: column;
		font-size: 18px;
		line-height: 30px;
		gap: 30px;
		text-align: center;
	}
}

.footer-subtitle {
	font-family: var(--font-secondary);
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	color: var(--color-5);
	text-transform: uppercase;
	letter-spacing: 3.5px;
	text-align: center;
}

.last-check-list-item {
	display: flex;
	flex-direction: column;
	gap: 4px;

	.and-more {
		font-family: var(--font-secondary);
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.25px;
		color: var(--color-1);

		@media screen and (max-width: $screen-m) {
			font-size: 14px;
			line-height: 22px;
		}
	}
}
.proof-paragraph {
	margin-top: 20px;
	font-size: 16px;
	line-height: 1.2em;
	text-align: center;
}
