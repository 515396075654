@import "Themes/constants.scss";

.root {
	font-family: var(--font-secondary);
	font-style: normal;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;

	>img {
		max-width: 65vw;
		min-width: 256px;
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		max-width: 430px;

		.title {
			font-weight: 700;
			font-size: 27px;
			line-height: 32px;
			text-align: center;
			color: var(--color-3);

			@media screen and (max-width: $screen-s) {
				font-size: 18px;
				line-height: 30px;
			}
		}

		.text {
			font-weight: 300;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			letter-spacing: 0.25px;
			color: var(--color-1);

			@media screen and (max-width: $screen-s) {
				font-size: 14px;
				line-height: 22px;
			}
		}
		
	}

	.social-container{
		>.social-link{
			margin: 12px
		}
	}
}

