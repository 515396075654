@import "Themes/constants.scss";

.root {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;

	.content-container {
		margin-left: 50px;

		.main-info-text {
			font-family: var(--font-secondary);
			font-style: normal;
			font-weight: 300;
			font-size: 18px;
			line-height: 30px;
			color: var(--color-1);
			margin: 4px 0px;

			@media (max-width: $screen-s) {
				font-size: 14px;
				line-height: 22px;
			}
		}

		.value-text {
			font-family: var(--font-primary);
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 28px;
			color: var(--color-1);
			margin: 4px 0px;

			@media (max-width: $screen-s) {
				font-size: 18px;
				line-height: 24px;
			}
		}

		.value-loading {
			background: linear-gradient(270deg, #e6e6e6 0%, rgba(230, 230, 230, 0) 100%);
			border-radius: 25px;
			width: 72px;
			height: 17.5px;
		}
	}

	.glowing-arrow {
		position: absolute;
		left: -20px;
	}
}
