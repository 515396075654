@import "Themes/constants.scss";

.root {
	background-image: url("/assets/images/pages/home/background/real-world.png");
	background-size: cover;
	background-repeat: no-repeat;
	padding-bottom: 80px;

}

.title {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 500;
	font-size: 48px;
	line-height: 60px;
	letter-spacing: 5px;
	text-transform: uppercase;
	color: var(--color-1);

	@media screen and (max-width: $screen-s) {
		font-size: 28px;
		line-height: 44px;
	}
}

.link{
	cursor: pointer;
}

.header {
	display: flex;
	justify-content: center;
	gap: 26px;
	padding-top: 80px;
	padding-bottom: 32px;
	margin-bottom: 70px;

	@media screen and (max-width: $screen-s) {
		padding-top: 50px;
		padding-bottom: 0;
	}
}


.neon {
	color: var(--color-3);
	text-shadow: 0px 0px 34px var(--color-6), 0px 0px 24px var(--color-7);
}

.orange {
	color: var(--color-6);
}