@import "Themes/constants.scss";

.root {
	font-family: var(--font-secondary);
	font-style: normal;
	font-weight: 500;
	font-weight: 300;
	font-size: 16px;
	line-height: 25px;
	color: var(--color-1);

	@media screen and (max-width: $screen-s) {
		font-size: 14px;
		line-height: 22px;
	}

	> a {
		text-decoration: none;
		color: var(--color-1);
	}
}
