@import "Themes/constants.scss";

.root {
	margin-left: 12vw;
	margin-right: 12vw;

	@media (max-width: $screen-m) {
		margin-left: 16px;
		margin-right: 16px;
	}
}

.title {
	display: flex;
	justify-content: center;
}

