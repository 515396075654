@import "Themes/constants.scss";

.root {
	display: flex;
	align-items: center;
}



.title {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	color: var(--color-1);

	padding-left: 10px;

	@media screen and (max-width: $screen-s) {
		font-size: 14px;
		line-height: 22px;
	}
}
